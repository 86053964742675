exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-hakkimizda-haberler-index-js": () => import("./../../../src/pages/hakkimizda/haberler/index.js" /* webpackChunkName: "component---src-pages-hakkimizda-haberler-index-js" */),
  "component---src-pages-hakkimizda-index-js": () => import("./../../../src/pages/hakkimizda/index.js" /* webpackChunkName: "component---src-pages-hakkimizda-index-js" */),
  "component---src-pages-hakkimizda-kvkk-index-js": () => import("./../../../src/pages/hakkimizda/kvkk/index.js" /* webpackChunkName: "component---src-pages-hakkimizda-kvkk-index-js" */),
  "component---src-pages-hakkimizda-sertifikalar-index-js": () => import("./../../../src/pages/hakkimizda/sertifikalar/index.js" /* webpackChunkName: "component---src-pages-hakkimizda-sertifikalar-index-js" */),
  "component---src-pages-hakkimizda-sirketimiz-index-js": () => import("./../../../src/pages/hakkimizda/sirketimiz/index.js" /* webpackChunkName: "component---src-pages-hakkimizda-sirketimiz-index-js" */),
  "component---src-pages-hakkimizda-uretim-index-js": () => import("./../../../src/pages/hakkimizda/uretim/index.js" /* webpackChunkName: "component---src-pages-hakkimizda-uretim-index-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kataloglar-fiyat-listeleri-index-js": () => import("./../../../src/pages/kataloglar/fiyat-listeleri/index.js" /* webpackChunkName: "component---src-pages-kataloglar-fiyat-listeleri-index-js" */),
  "component---src-pages-kataloglar-index-js": () => import("./../../../src/pages/kataloglar/index.js" /* webpackChunkName: "component---src-pages-kataloglar-index-js" */),
  "component---src-pages-kataloglar-urun-belgeleri-index-js": () => import("./../../../src/pages/kataloglar/urun-belgeleri/index.js" /* webpackChunkName: "component---src-pages-kataloglar-urun-belgeleri-index-js" */),
  "component---src-pages-urunler-index-js": () => import("./../../../src/pages/urunler/index.js" /* webpackChunkName: "component---src-pages-urunler-index-js" */),
  "component---src-templates-product-details-js": () => import("./../../../src/templates/ProductDetails.js" /* webpackChunkName: "component---src-templates-product-details-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/Products.js" /* webpackChunkName: "component---src-templates-products-js" */)
}

